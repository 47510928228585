@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


:root {
  --primary-color: #222029;
  --secondary-color: #4B7BCD;
  --black: black;
  --white: white;
  --gray: #f4f4f4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
    scroll-behavior: smooth;
}

::-webkit-scrollbar{width:5px;}
::-webkit-scrollbar-thumb{background-color:var(--secondary-color);}
::-webkit-scrollbar-track{background-color:var(--primary-color);}



/* Basics */

.top-5{
  margin-top: 5px !important;
}

.top-15{
  margin-top: 15px !important;
}

.top-25{
  margin-top: 25px !important;
}

.top-50{
  margin-top: 50px !important;
}

.left-5{
  margin-left: 5px !important;
}

.left-15{
  margin-left: 15px !important;
}

.left-25{
  margin-left: 25px !important;
}

.left-50{
  margin-left: 50px !important;
}

.right-5{
  margin-right: 5px !important;
}

.right-15{
  margin-right: 15px !important;
}

.right-25{
  margin-right: 25px !important;
}

.right-50{
  margin-right: 50px !important;
}


.next{
display: flex !important;
align-items: center;
justify-content: space-between;
}

.together{
  display: flex;
  align-items: center;
}
.together svg{
  margin-right: 5px;
  color : var(--primary-color);
}

.color-primary{
  color: var(--primary-color);
}

.color-secondary{
  color: var(--secondary-color) !important;
}

.italic{
  font-style: italic;
}

.bold{
  font-weight: bold;
}

.not-found{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88.5vh;
}

a{
  text-decoration: none !important;
  color: var(--black) ;
}

.page-container{
  padding: 175px 75px 75px 75px;
}



/* Navbar */

nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  position: fixed;
  top: 0;
  left: 0;
  padding : 50px 75px;
  width: 100%;
  border-bottom: 3px solid var(--primary-color);
  z-index: 1000;
  transition: all 0.5s ease-in-out;
}

.navbar-scrolled {
  padding: 25px 75px;
  border-bottom: 3px solid var(--secondary-color);
}
.nav-logo{
  font-weight: bold;
  color: var(--white) !important;
}

.nav-logo a{
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}


.nav-right{
  display: flex;
  align-items: center;
}

/* src/styles/navbar.css */

.nav-link {
  position: relative;
  transition: color 0.3s ease-in-out;
  color: var(--white);
  font-size: 1.2rem;

}

.nav-link:hover {
  color: var(--secondary-color);
}

.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: var(--secondary-color);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::after {
  transform: scaleX(1);
  background-color: var(--secondary-color);

  transform-origin: bottom left;
}

.nav-active {
  color: var(--white) !important;
  font-weight: bold;
}


.ham-menu{
  display: none;
}

.nav-popup-title{
  font-size: 1.2rem;
  font-weight: bold;
  
}

.nav-popup-logo{
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 5px;
}

.nav-popup-up{
  display: flex;
  border-bottom: 3px solid var(--secondary-color);
  padding-bottom: 10px;
  align-items: center;
  margin-bottom: 25px;
}

.nav-popup-upper{
  display: flex;
  flex-direction: column;
  padding : 25px;
}


/* Landing Page */
.landing{
  display: flex;
  overflow-x: hidden;
  height: 100vh;
  background-color: var(--primary-color);
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding: 150px 75px 0 75px;
}

.landing-text{
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  width: 70%;
}

.landing-text-title{
  font-size: 5rem;
  margin-bottom: 25px;

}

.landing-text-subtitle{
  width: 50%;
  margin-bottom: 25px;
  font-size: 1.5rem;
  font-weight:400 ;
}

.landing-text-socials{
  display: flex;
  margin-bottom: 25px;
}

.landing-text-socials a{
  margin: 0 10px;
  font-size: 2rem;
  color: white;
  transition: all 0.3s ease;
}

.landing-text-socials a svg{
  color: white;
}

.landing-text-button{
  display: flex;
  margin-top: 50px;

}
.landing-text-button a{
  padding: 15px 50px;
  background-color: var(--secondary-color);
  color: white;
  font-size: 1.2rem;
  border-radius: 50px 50px 0px 50px;
  transition: all 0.3s ease;
}

.landing-image{
  width: 40%;
  height: 100%;
  font-weight: bold;
  transition: all 0.3s ease;
  cursor: pointer;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: relative;
  z-index: 1;

}

.landing-image img{
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  object-fit: cover;
}

.landing-line{
  width: 100%;
  height: 10px;
  background-color: var(--secondary-color);
  margin-top: 25px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.landing-animation{
  position: absolute;
  bottom: 20px;
  left: 0px;
  z-index: 0;
  width: 100%;
  height: 20px;
  background-color: var(--white);

}

/* About */
.about{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  color: var(--primary-color);
}

.about-text{
  padding: 125px 125px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.skills-container {
  display: flex;
  flex-direction: column;
  padding: 75px 75px;
}

.skills-container h3{
  font-size: 1.5rem;
  font-weight: bold;
}


.skills-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;  
  margin-top:30px;
}

.skill-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  transition: all 0.3s ease;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: var(--primary-color);
}

.skill-item:hover {
  transform: scale(1.01);
}

.skill-item svg {
  margin-right: 15px;
  color: var(--secondary-color);
  font-size: 1.5rem;
}

.skill-item .skill-level svg{
  color: var(--secondary-color);
  margin-right: 5px;
}

.title-section{
  font-size: 2rem;
}
.text-section{
  font-size: 1.2rem;
  margin-top: 25px;
  width: 50%;
  text-align: center;
}

.small-title{
  color: var(--secondary-color);
  margin-bottom: 5px;
  font-weight: bold;
}


.projects-btn{
  padding: 15px 50px;
  background-color: var(--secondary-color);
  color: white;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

/* Education */
.education-container {
  color: var(--white);
  padding : 50px 75px;
  background-color: var(--secondary-color);
  
}

.education-container .small-title{
  color: var(--white);
}

.education-timeline {
  display: flex;
  flex-direction: column;
}

.education-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--white);
  padding: 10px 0;

}

.education-item h4 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.education-school {
  font-size: 1rem;
  margin-bottom: 10px;
}

.education-period {
  font-size: 1rem;
}

.education-circle{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  margin-right: 25px;
}


/* Projects */	

.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 30px;
}

.project-card{
  display: flex;
  flex-direction: column;
  padding: 15px;
  transition: all 0.3s ease;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: var(--primary-color);
  border-bottom: 2px solid var(--secondary-color);
}

.project-card:hover {
  transform: scale(1.01);
}

.project-link{
  color: var(--secondary-color);
  padding-bottom: 3px;
  border-bottom: 1px solid var(--secondary-color);
}

.project-text{
  padding: 75px 75px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.project-title{
  padding-top: 15px;
}


/* Contact */
form{
  display: flex;
  flex-direction: column;
}
.rijForm{
  display: flex; 
  width: 100%;
  justify-content: space-between;
}
.colomForm{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
 
  margin-bottom: 20px;
}
.inputlabel{
  font-weight: 400;
}
.inputField{
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding-left: 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
}
.rijFormFull{
  width: 100%;
  padding-right: 20px;
}
.inputFieldBig{
  width: 100%;
  height: 25vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding : 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  resize: none;
  transition: 0.5s;
  outline: none;
}
.inputFieldcat{
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding-left: 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
}
.btnadd{
  background-color: var(--secondary-color);
  border-radius: 5px;
  color: var(--white) !important;
  padding: 1em 5em;
  font-weight: 700;
  border : 0;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 20px;
  
}
.inputFieldBig:focus, .inputField:focus{
  
  border : 1px solid var(--primary-color);
}

.green{
  margin-top: 5px;
  padding : 20px 20px;
  display: flex;
  align-items: center;
  background-color: #3ccb6d;
  color: white;
  border-radius: 5px;
}
.red{
  margin-top: 5px;
  padding : 20px 20px;
  display: flex;
  align-items: center;
  background-color: #ff3f5f;
  color: white;
  border-radius: 5px;

}
.red svg, .green svg{
  margin-right: 10px;
  color: white;
  font-size: 1.5em;
}

.contact-info svg{
  color: var(--secondary-color);
}


/* Footer */

footer{
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 50px 100px 50px;
  background-color: var(--secondary-color);
  color: var(--white);
}

.footer-part-text a {
  color: var(--white) !important;
}

.footer-part{
  width: 30%;
}

.footer-part-title{
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-part-text{
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  color: var(--white);
}

.footer-part-text a{
  display: flex;
  align-items: center;
}

.footer-part-text a svg, .footer-part-text svg{
  margin-right: 5px;
}


/* 1920 x 1080 */
@media (min-width: 1900px) {
  nav{
    padding: 75px 150px;
  }
  .landing{
    padding: 250px 50px 0 150px;
  }
}

/* Mobile */
@media (max-width: 768px) {
  
  nav {
    padding: 15px 25px;
  }

  .landing{
    padding: 75px 25px 0 25px;
    height: 80vh;
  }
  .landing-animation{
    display: none;
  }

  .landing-line{
    display: none;
  }



  .nav-right{
    display: none;
  }

  .navbar-scrolled{
    padding: 15px 25px;
  }

  .nav-menu-icon{
    color: var(--white) !important;
  }

  .title-section{
    font-size: 1.7rem;
  }

.nav-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100%;
  background-color: var(--primary-color);
  color: white !important;
  transition: right 0.3s ease;
  z-index: 1 !important;
}

.page-container{
  padding: 75px 25px 25px 25px;
}

.project-text{
  padding: 25px 25px;
}

.project-grid{
  grid-template-columns: repeat(1, 1fr);
}


footer{
  padding: 50px 25px 25px 25px;
  flex-direction: column;
}

.footer-part{
  width: 100%;
  margin-bottom: 50px;
}

.nav-popup.open {
  right: 0;
}

.nav-popup  {
  list-style: none;
}

.nav-menu-icon {
  position: relative;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 2 !important;
}

.nav-popup .nav-link {
  margin: 1rem 0;
  margin-left: 0px !important;
}

.nav-popup .nav-link  {
  text-decoration: none;
}

.ham-menu{
  display: block;
}

form{
  min-height: fit-content;
}

.rijForm{
  flex-direction: column;
  width: 100%;
}
.colomForm{
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 20px;
}
.inputFieldcat{
  height: 8vh;
}
.inputField{
  padding : 2em 1em;
}
.rijFormFull{
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.inputFieldBig{
  padding : 1em 1em;
  height:  30vh;
}
.btnadd{
  width: 100%;
  text-align: center;
  padding : 1em 0;
}
.red,.green{
  padding: 10px 10px;
  flex-direction: column;
  text-align: center;
}
.red svg, .green svg{
  margin-right: 0;
  margin-bottom: 15px;
}

  .landing{
    padding: 75px 25px 0 25px;
    flex-direction: column;
  }

  .landing-text{
    width: 100% !important;
    text-align: center;
  }

  .landing-text-title{
    font-size: 3rem;
  }

  .landing-text-subtitle{
    width: 100% !important;
    text-align: center;
  }

  .text-section{
    width: 100% !important;
  }
  .projects-btn{
    padding: 15px 75px;
  }
  .landing-text-socials{
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;

  }

  .landing-text-button{
    margin-top: 25px;
    align-items: center;
    justify-content: center;
  }

  .landing-text-button a{
    padding: 15px 75px;
  }

  .landing-image{
    display: none;
  }

  .about{
    flex-direction: column;
  }

  .about-text{
    padding: 75px 25px;
  }

  .skills-container {
    padding: 75px 25px;
  }

  .skills-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .skills-grid {
    gap: 10px;
  }

  .skills-grid {
    margin-bottom: 20px;
  }

  .skills-grid {
    margin-top: 20px;
  }

  .education-container {
    padding: 50px 25px;
  }

  .education-timeline {
    flex-direction: column;
  }

  .education-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
  }


}


@media (max-height: 768px) {
  .landing-text-title {
    font-size: 2.5rem;
  }

  .landing{
    height: 95vh;
  }

  .landing-text-subtitle {
    font-size: 1rem;
  }

  .landing-text-button a {
    padding: 10px 25px;
  }

  .landing-text-socials a {
    font-size: 1.5rem;
  }

  .landing-text-socials {
    margin-bottom: 25px;
  }
  
  .landing-text-button a {
    padding: 10px 50px;
  }
}
